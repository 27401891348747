.dropzone {
    padding: 10px;
    height: 100px;
    background-color: #fff7f7;
    cursor: move;
    --antd-wave-shadow-color: none;
    &::after{
        border: inherit;
    }
    .dropzone-internal{
        border: 1px dashed rgba(0,0,0, 0.5);
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
   
}