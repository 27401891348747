@mixin for-phone-only {
  @media (min-width: 320px) and (max-width: 480px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1025px) and (max-width: 1280px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1281px) {
    @content;
  }
}

@mixin mobile {
    @include for-phone-only {
        @content;
    }
    @include for-tablet-portrait-up{
        @content;
    }
}
