@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css?family=Roboto:300&display=swap');
@import './styles/buttons.scss';
@import './styles/menu.scss';
@import './styles/media.scss';

html, body, head {
    height: 100%;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
}

.bg-dark{
    background-color: #001529;
}

.ant-form-item{
    margin: 0px !important;
}
