.ordering-list {
    width: 300px;
    display: flex;
    flex-direction: column;
    padding:  0;
    margin: 0;
}

.is-dragging {
    margin-bottom: 100px;
}