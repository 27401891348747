@import '../../../../styles/media.scss';

.page-template {
  height: 100% !important;
  width: 100%;
  position: absolute;

  &__body {
    margin-left: 12.5rem;
    height: 100%;
    width: 100%;
    @include mobile {
      margin-left: 0;
    }
  }

  &__content {
    margin: 1.5rem 1rem 0;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    margin-top: 100px;
    margin-bottom: 35px;
    height: 100%;
    padding: 1.5rem; 
    background: #FFF; 
    overflow-y: auto;
  }
}
