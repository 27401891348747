.blacklist-table{
    max-height: 50% !important;
    width: 100%;
    margin-top: 20px;

    &__pagination{
        margin-top: 10px !important;
        float: right;
    }
}

.ant-table-body-inner, .ant-table-body{
    max-height: 100% !important;
}


.blacklist-options {
    max-width: 100% !important;
}

.blacklist-column {
    width: 150px;
    justify-content: center;
    padding: 2px;

    .anticon {
        margin-top: 3px;
        margin-right: 3px;
        float: right !important;
        cursor: pointer;
        color: #bfbfbf;
    }
}