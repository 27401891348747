@import '../../../../styles/colors.scss';

$width: 37.5rem;
$height: 25rem;

.login-page {
    margin-top: 100px; 
    background-color: $bg-color;
    width: $width;
    height: $height;
    border-radius: 0;
    box-shadow: 0.0625rem 0.0625rem 0.625rem 0.0625rem $light-shadow-color;

    .logo img {
        filter: none;
        margin-bottom: 3.125rem; 
        // display: block;
    }

    @media screen and (max-width: 360px) {
        margin-top: 2.5rem; 
        width: 80vw;
        height: 40vh;
        .logo img {
           height: 3.5rem;
        }
    }
}