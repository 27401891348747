@import '../../../../styles/colors.scss';
@import '../../../../styles/media.scss';

.header {
    background-color: $header-color;
    padding: 0;
    display: flex;
    align-items: center !important;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
    position: fixed;
    z-index: 1000;
    width: 100% !important;
    text-align: right;

    .header-menu-button{
        width: 50px;
        display: none;
        margin: 10px;
    }

    @include mobile {
        background-color: $primary-color;

        .logo{
            display: block !important;
        }
        .header-menu-button{
            display: block !important;
        }
    }
    
}