.service-item {
    cursor: grab;
    list-style: none;
    margin-bottom: 10px;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    max-height: 50px;
    display: flex;
    justify-content: space-between;
    text-align: left;
    padding: 10px;
    align-items: center;
    .order {
        font-weight: 600;
    }
    .service-name{
        font-weight: 500;
    }
}

.is-dragging {
    cursor: grabbing;
}