@import '../../../../styles/media.scss';

.logo {

  width: 100%;
  text-align: center;

  img {
    height: 3.5rem;
    width: 9.375rem;
    margin-bottom: 2.75rem; 
    margin-top: 1.75rem; 
    filter: drop-shadow(1px 1px 8px #000);
  }
}

@include mobile {
  .logo {
    text-align: left;

    img {
      height: 2.5rem;
      width: 30%;
      margin-top: 0;
      margin-left: 10px;
    }
  }
}


@include for-phone-only {
  .logo {
    width: initial;
  }
}


