@import '../../../../styles/colors.scss';
@import '../../../../styles/media.scss';

.raw-template {
    height: 100vh;
    display: flex;
    align-items: center;
    background-color: $primary-color;

    .logo {
        text-align: center;
        img {
            width: 12.5rem;
            margin-bottom: 0;
        }
    }   

    @include mobile {
        padding-top: 3rem;
        
        .logo img {
            height: 4rem;
        }
    }

}