@import url(https://fonts.googleapis.com/css?family=Roboto:300&display=swap);
.ant-btn-primary {
  background-color: #001529;
  border-radius: 0; }
  .ant-btn-primary:hover {
    background-color: #FFF;
    color: #001529;
    border-color: #001529; }

.ant-menu {
  font-weight: 600;
  color: yellow; }

.ant-menu.ant-menu-dark .ant-menu-item-selected.nav-item {
  background-color: #ed1129; }

html, body, head {
  height: 100%;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased; }

.bg-dark {
  background-color: #001529; }

.ant-form-item {
  margin: 0px !important; }

.raw-template {
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: #011528; }
  .raw-template .logo {
    text-align: center; }
    .raw-template .logo img {
      width: 12.5rem;
      margin-bottom: 0; }
  @media (min-width: 320px) and (max-width: 480px) {
    .raw-template {
      padding-top: 3rem; }
      .raw-template .logo img {
        height: 4rem; } }
  @media (min-width: 768px) and (max-width: 1024px) {
    .raw-template {
      padding-top: 3rem; }
      .raw-template .logo img {
        height: 4rem; } }

.logo {
  width: 100%;
  text-align: center; }
  .logo img {
    height: 3.5rem;
    width: 9.375rem;
    margin-bottom: 2.75rem;
    margin-top: 1.75rem;
    -webkit-filter: drop-shadow(1px 1px 8px #000);
            filter: drop-shadow(1px 1px 8px #000); }

@media (min-width: 320px) and (max-width: 480px) {
  .logo {
    text-align: left; }
    .logo img {
      height: 2.5rem;
      width: 30%;
      margin-top: 0;
      margin-left: 10px; } }

@media (min-width: 768px) and (max-width: 1024px) {
  .logo {
    text-align: left; }
    .logo img {
      height: 2.5rem;
      width: 30%;
      margin-top: 0;
      margin-left: 10px; } }

@media (min-width: 320px) and (max-width: 480px) {
  .logo {
    width: auto;
    width: initial; } }

.login-page {
  margin-top: 100px;
  background-color: #f0f2f5;
  width: 37.5rem;
  height: 25rem;
  border-radius: 0;
  box-shadow: 0.0625rem 0.0625rem 0.625rem 0.0625rem rgba(255, 255, 255, 0.637); }
  .login-page .logo img {
    -webkit-filter: none;
            filter: none;
    margin-bottom: 3.125rem; }
  @media screen and (max-width: 360px) {
    .login-page {
      margin-top: 2.5rem;
      width: 80vw;
      height: 40vh; }
      .login-page .logo img {
        height: 3.5rem; } }


.side-menu {
  overflow: auto;
  height: 100vh;
  position: fixed !important;
  left: 0;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.26);
  z-index: 10000; }

.page-template {
  height: 100% !important;
  width: 100%;
  position: absolute; }
  .page-template__body {
    margin-left: 12.5rem;
    height: 100%;
    width: 100%; }
    @media (min-width: 320px) and (max-width: 480px) {
      .page-template__body {
        margin-left: 0; } }
    @media (min-width: 768px) and (max-width: 1024px) {
      .page-template__body {
        margin-left: 0; } }
  .page-template__content {
    margin: 1.5rem 1rem 0;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    margin-top: 100px;
    margin-bottom: 35px;
    height: 100%;
    padding: 1.5rem;
    background: #FFF;
    overflow-y: auto; }

.select-country-wrapper {
  width: 85%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row; }
  .select-country-wrapper .select {
    width: 20%; }

.header {
  background-color: #FFF;
  padding: 0;
  display: flex;
  align-items: center !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
  position: fixed;
  z-index: 1000;
  width: 100% !important;
  text-align: right; }
  .header .header-menu-button {
    width: 50px;
    display: none;
    margin: 10px; }
  @media (min-width: 320px) and (max-width: 480px) {
    .header {
      background-color: #011528; }
      .header .logo {
        display: block !important; }
      .header .header-menu-button {
        display: block !important; } }
  @media (min-width: 768px) and (max-width: 1024px) {
    .header {
      background-color: #011528; }
      .header .logo {
        display: block !important; }
      .header .header-menu-button {
        display: block !important; } }

.service-item {
  cursor: -webkit-grab;
  cursor: grab;
  list-style: none;
  margin-bottom: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  max-height: 50px;
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding: 10px;
  align-items: center; }
  .service-item .order {
    font-weight: 600; }
  .service-item .service-name {
    font-weight: 500; }

.is-dragging {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.ordering-list {
  width: 300px;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0; }

.is-dragging {
  margin-bottom: 100px; }

.dropzone {
  padding: 10px;
  height: 100px;
  background-color: #fff7f7;
  cursor: move;
  --antd-wave-shadow-color: none; }
  .dropzone::after {
    border: inherit; }
  .dropzone .dropzone-internal {
    border: 1px dashed rgba(0, 0, 0, 0.5);
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%; }

.dropzone-list {
  padding: 0; }
  .dropzone-list__item {
    cursor: pointer; }

.service-ordering {
  width: 300px; }
  .service-ordering__controls {
    text-align: right; }

.load-services__controls {
  text-align: right; }

.ant-modal-content {
  border-radius: 0px; }


.blacklist-table {
  max-height: 50% !important;
  width: 100%;
  margin-top: 20px; }
  .blacklist-table__pagination {
    margin-top: 10px !important;
    float: right; }

.ant-table-body-inner, .ant-table-body {
  max-height: 100% !important; }

.blacklist-options {
  max-width: 100% !important; }

.blacklist-column {
  width: 150px;
  justify-content: center;
  padding: 2px; }
  .blacklist-column .anticon {
    margin-top: 3px;
    margin-right: 3px;
    float: right !important;
    cursor: pointer;
    color: #bfbfbf; }



